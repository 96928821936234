import request from '@/utils/request';
export var defaultUsersProductsDiscountData = {
  id: 0,
  enabled: true,
  bazarDharaDiscount: 0,
  userId: null,
  user: {
    id: null
  },
  productId: null,
  product: {
    id: null
  }
};
export var getUPD = function getUPD(params) {
  return request({
    url: '/users-products-discounts',
    method: 'get',
    params: params
  });
};
export var getUPDById = function getUPDById(id) {
  return request({
    url: "/users-products-discounts/".concat(id),
    method: 'get'
  });
};
export var updateUPD = function updateUPD(id, data) {
  return request({
    url: "/users-products-discounts/".concat(id),
    method: 'patch',
    data: data
  });
};
export var deleteUPD = function deleteUPD(id) {
  return request({
    url: "/users-products-discounts/".concat(id),
    method: 'delete'
  });
};
export var createUPD = function createUPD(data) {
  return request({
    url: '/users-products-discounts/',
    method: 'post',
    data: data
  });
};